.main__Rules{
position: relative;
height: 100%;
}
h1{
  text-align: center;
  padding-top: 20px;
}
.levels__Rules,.imgs__rules{
  text-align: center;
}

.align-cent__Rules{
  display: flex;
  justify-content: center;
}
.ul1__Rules{
  padding-left: 2rem !important;
}
.ul2__Rules{
  padding-left: 3rem !important;
}
.font-20__Rules{
  font-size: 20px ;
}
@media only screen and (min-width: 1025px) {
  .hide-desktop {
    display: none;
  }

}

@media only screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }
}



