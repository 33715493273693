.main__home{
    width: 100%;
    height: 600px;
    position: relative;
    }
    .img1__home{
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    .connnect1__home{
      position: absolute;
      top: 350px;
      left: 500px;
      width: 15%;
    }
    .connnect2__home{
      position: absolute;
      top: 350px;
      left: 1000px;
      width: 15%;
    }
  
    .text__home{
        position: absolute;
        top: 22%;
        left: 35%;
        font-size: 40px;
        text-align: center;
        color: white !important;
      }

      .links__home img{
        width: 100%;
      }
      .links__home {
        color: white;
      }
    
    @media only screen and (max-width: 1366px) { 
      .connnect1__home{
        position: absolute;
        top: 70%;
        left: 430px;
        width: 15%;
      }
      .connnect2__home{
        position: absolute;
        top: 70%;
        left: 750px;
        width: 15%;
      }
      .text__home{
        position: absolute;
        top: 25%;
        left: 35%;
        font-size: 35px;
        text-align: center;
        color: white !important;
      }
    }
    @media only screen and (max-width: 768px) {
      .connnect1__home{
        position: absolute;
        top: 70%;
        left: 60px;
        width: 243px;
        width: 35%;
      }
      .connnect2__home{
        position: absolute;
        top: 70%;
        left: 230px;
        width: 243px;
        width: 35%;
      }
      .text__home{
        position: absolute;
        top: 25%;
        left: 16%;
        font-size: 25px;
        text-align: center;
        color: white !important;
      }
    }
    