.main__contact{
position: relative;
height: 100%;
}

.container__contact{
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.div__contact{
  padding-bottom: 20px;
  font-size: 25px;
  padding-left: 30px;
}



.input__contact{
  width: 95%;
  background-color: #D9D9D9;
  border: 0;
  border-radius: 10px;
  height: 60px ;
  padding: 30px ;
}

.divBtn__contact{
  text-align: center;
}

.btn__contact{
  height: 60px;
  border: 0;
  border-radius: 10px;
  background-color: #70242B ;
  color: white;
  font-size: 30px;
}

@media only screen and (min-width: 1025px) {
  .hide-desktop {
    display: none;
  }
  .div__contact{
    padding-left: 30px;
  }
  .btn__contact{
    width: 500px;
  }

}

@media only screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }
  .div__contact{
    padding-top: 30px;
  }
  .btn__contact{
    width: 100%;
  }
  .divBtn__contact{
    padding-right: 20px;
  }
}



