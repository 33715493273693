.main__header{
width: 100%;
height: 600px;
position: relative;
}
.img1__header{
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.connnect1__header{
  position: absolute;
  top: 430px;
  left: 80px;
  width: 10%;
}
.links__navbar img{
  width: 100%;
}
.connnect2__header{
  position: absolute;
  top: 430px;
  left: 330px;
  width: 10%;
}

@media only screen and (max-width: 1366px) {
  .connnect1__header{
    position: absolute;
    top: 65%;
    left: 45px;
    width: 10%;
  }
  .connnect2__header{
    position: absolute;
    top: 65%;
    left: 250px;
    width: 10%;
  }
}

@media only screen and (max-width: 768px) {
  .connnect1__header{
    position: absolute;
    top: 89%;
    left: 75px;
    width: 243px;
  }
  .connnect2__header{
    position: absolute;
    top: 98.5%;
    left: 75px;
    width: 243px;
  }
}
