.main__legal{
position: relative;
height: 100%;
}

@media only screen and (min-width: 1025px) {
  .hide-desktop {
    display: none;
  }

}

@media only screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }
}



