.footer__footer{
  padding-top: 20px;
  background-color: #3C3A3C;
  height: 100%;
  width: 100%;
  color: white;
  padding-bottom: 10px;
}

.text-align__footer{
  text-align: center;
}
.links__footer {
  color: white;
  text-decoration: none;
}
.ul__footer{
  list-style: none;
  text-align: center;
}
.padd-10__footer{
  padding-left: 10px;
  padding-right: 10px;
}
.links__footer{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  text-decoration: none;
  color: white;
}
.links__footer:hover{
  color: black;
}

.align-cent__footer{
  display: flex;
  justify-content: center;
}
.hr__footer{
  width: 80%;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

@media only screen and (min-width: 1025px) {
  .hide-desktop {
    display: none;
  }

}

@media only screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }
}