.img1__navbar {
  padding-left: 10px;
}
.img2__navbar {
  width: 55px;
}


.align-txt__navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.navbar__navbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.links__navbar {
  color: black;
  text-decoration: none;
}

.padd-top-bot__navbar {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (min-width: 1367px) {
  .img__navbar {
    width: 100%;
    
  }
  .font__navbar {
    font-size: 35px;
  }
}
@media only screen and (max-width: 1366px) {
  .img__navbar {
    width: 70%;
    font-size: 15px;
  }
  .align-txt__navbar {
    text-align: right;
    font-size: 10px;
  }
  .font__navbar {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1025px) {
  .hide-desktop {
    display: none;
  }

}

@media only screen and (max-width: 1024px) {
  .hide-mobile {
    display: none;
  }
  .ul__navbar{
    list-style: none;
  }
}

* {
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #f5f6fa;
}
.wrapper .sidebar {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 215px;
  height: 100%;
  padding: 20px 0;
  transition: all 2s ease !important;
  z-index: 10;
}
.wrapper .sidebar .profile {
  margin-bottom: 30px;
  text-align: center;
}
.wrapper .sidebar ul li a {
  display: block;
  padding: 13px;
  border-bottom: 1px solid black;
  color: black;
  font-size: 16px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  padding-bottom: 10px;
}
.wrapper .sidebar ul li a .icon {
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}
ul {
  padding-left: 0px !important;
}
ol {
  padding-left: 0px !important;
}
.chyzn{
  font-size: 30px;
}
.fa-bars{
  z-index: 11;
}

.wrapper .section {
  width: calc(100% - 190px);
  transition: all 0.5s ease !important;
}
.wrapper .section .top_navbar {
  background: white;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.wrapper .section .top_navbar .chyzn a {
  font-size: 28px;
  color: #f4fbff;
}
.wrapper .section .top_navbar .chyzn a:hover {
  color: #a2ecff;
}
body.active .wrapper .sidebar {
  left: -215px;
}
body.active .wrapper .section {
  margin-left: 0;
  width: 100%;
}
